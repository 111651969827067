import get from 'lodash/get'
import { ReactElement, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
    Box,
    Card,
    CardBody,
    Flex,
    Heading,
    SimpleGrid,
    Stack,
    Text,
    useToast,
} from '@chakra-ui/react'
import Timeline from '../../../../features/timeline/Timeline.component'
import API_ENDPOINTS from '../../../../services/API/apiEndpoints.constants'
import {
    generalGetAPI,
    generalPostAPI,
} from '../../../../services/API/general.api'
import { useLoading } from '../../../../services/contexts/Loading.context'
import { usePartner } from '../../../../services/contexts/partner.context'
import { useContractService } from '../../../../services/contract/Contract.services'
import {
    baseErrorToastOptions,
    toFirstCharLowercase,
} from '../../../../utils/functions.utils'
import { formatDate } from '../../../../utils/localization/culture.utils'
import { ContractTraceType, ProductType } from '../../../../utils/types/types'
import NumberFormatterKeyInformation from '../../../../features/numberFormatterKeyInformation/numberFormatterKeyInformation.feature'

type ContractCalculationData = Record<string, number>

type ContractTraceItem = {
    type: string
    date: string
    userName?: string
    userNumber?: string
}

const CalculationCard = ({
    label,
    value,
    currency,
}: {
    label: string
    value: number
    currency?: string
}) => (
    <Card size="md" boxShadow="sm" height="100%">
        <CardBody>
            <Flex flexDirection="column" gap={2}>
                <Heading size="md">{label}</Heading>
                <Box ml={-1}>
                    <NumberFormatterKeyInformation
                        value={value}
                        currency={currency}
                    />
                </Box>
            </Flex>
        </CardBody>
    </Card>
)

const TraceCard = ({ item }: { item: ContractTraceItem }) => {
    const translate = useTranslation().t
    return (
        <Card size="md" boxShadow="sm" key={`${item.type}-${item.date}`}>
            <CardBody>
                <Flex justifyContent="space-between">
                    <Stack gap={1}>
                        <Heading size="md">
                            {translate(
                                toFirstCharLowercase(
                                    ContractTraceType[item.type as any]
                                )
                            )}
                        </Heading>
                        <Text size="md">
                            {item.userName ?? item.userNumber}
                        </Text>
                    </Stack>
                    <Stack gap={1}>
                        <Text size="md">{formatDate(item.date)}</Text>
                    </Stack>
                </Flex>
            </CardBody>
        </Card>
    )
}

export default function ContractDetailTracer(): ReactElement {
    const { contract, product } = useContractService()
    const { activePartner } = usePartner()
    const translate = useTranslation().t
    const toast = useToast()
    const { globalLoading, stopGlobalLoading } = useLoading()

    const [contractTraceItems, setContractTraceItems] = useState<
        ContractTraceItem[]
    >([])
    const [calculationData, setCalculationData] =
        useState<ContractCalculationData>({})
    const [calculationExcludeRules, setCalculationExcludeRules] = useState<
        RegExp[]
    >([])

    useEffect(() => {
        const fetchContractTraceData = async () => {
            const loadingID = globalLoading()
            const response = await generalGetAPI(
                `${API_ENDPOINTS.contractTrace}?contractNumber=${contract.contractNumber}`
            )
            response.isOk
                ? setContractTraceItems(response.data?.items)
                : toast(baseErrorToastOptions(response.message))
            stopGlobalLoading(loadingID)
        }
        fetchContractTraceData()
    }, [])

    useEffect(() => {
        if (!contract) return
        const fetchContractCalculations = async () => {
            const loadingID = globalLoading()
            const response = await generalPostAPI(
                API_ENDPOINTS.contractActionsCalculate,
                { contract }
            )
            response.isOk
                ? setCalculationData(response.data)
                : toast(baseErrorToastOptions(response.message))
            stopGlobalLoading(loadingID)
        }
        fetchContractCalculations()
    }, [contract])

    useEffect(() => {
        const baseExcludeRules = [/[a-z]*Vat/i, /startDate/]
        if (
            ![
                ProductType.FinancialLeasing,
                ProductType.OperationalLeasing,
            ].includes(product.productType)
        ) {
            baseExcludeRules.push(/totalInterestEarning/)
        }
        setCalculationExcludeRules(baseExcludeRules)
    }, [product])

    return (
        <Box pb={12}>
            <Box
                pb={12}
                display={['flex', 'flex', 'flex', 'flex', 'flex']}
                flexWrap={'nowrap'}
                overflowX={'auto'}
                alignItems={'center'}
                gap={4}
                sx={{
                    '::-webkit-scrollbar': {
                        display: 'none',
                    },
                }}
            >
                <SimpleGrid
                    minChildWidth="200px"
                    spacing={4}
                    mt={4}
                    width="100%"
                >
                    {Object.keys(calculationData)
                        .filter(
                            (k) =>
                                !calculationExcludeRules.some((r) =>
                                    r.test(k)
                                ) && get(calculationData, k, 0) > 0
                        )
                        .map((key) => (
                            <CalculationCard
                                key={key}
                                label={translate(key)}
                                value={get(calculationData, key, 0)}
                                currency={activePartner?.currencyName}
                            />
                        ))}
                    {Object.keys(
                        contract?.calculatedFieldsInfo?.partRegistrationInfo ||
                            {}
                    )
                        .filter(
                            (k) =>
                                get(
                                    contract?.calculatedFieldsInfo
                                        ?.partRegistrationInfo,
                                    k,
                                    0
                                ) > 0
                        )
                        .map((key) => (
                            <CalculationCard
                                key={key}
                                label={translate(key)}
                                value={get(
                                    contract?.calculatedFieldsInfo
                                        ?.partRegistrationInfo,
                                    key,
                                    0
                                )}
                                currency={activePartner?.currencyName}
                            />
                        ))}
                </SimpleGrid>
            </Box>
            <Timeline />
            <Stack spacing={4} mt={4}>
                {contractTraceItems.map((item) => (
                    <TraceCard key={`${item.type}-${item.date}`} item={item} />
                ))}
            </Stack>
        </Box>
    )
}
