import { ReactElement, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { Box, chakra, Flex, Heading, Link, useToast } from '@chakra-ui/react'

import GenericBox from '../../components/genericBox/genericBox'
import API_ENDPOINTS from '../../services/API/apiEndpoints.constants'
import { generalGetAPI } from '../../services/API/general.api'
import { useLoading } from '../../services/contexts/Loading.context'
import { useContractService } from '../../services/contract/Contract.services'
import { baseErrorToastOptions } from '../../utils/functions.utils'
import { formatDate } from '../../utils/localization/culture.utils'
import { ContractTimelineDTO } from '../../utils/types/types'

enum ParsedTimeLineTypes {
    parent,
    current,
    child,
    parentCurrent,
    currentChild,
}
interface ParsedTimeline {
    date: string[]
    type: ParsedTimeLineTypes
    label?: string
    contractNumber?: string
}

export default function Timeline(): ReactElement {
    const { contract } = useContractService()
    const navigate = useNavigate()
    const { globalLoading, stopGlobalLoading } = useLoading()
    const translate = useTranslation().t
    const toast = useToast()
    const [timeline, setTimeline] = useState<Partial<ContractTimelineDTO>>({})
    const activeColor = 'primary.500'
    const inactiveColor = 'primary.700'

    const getContractTraceData = async (): Promise<void> => {
        const loadingID = globalLoading()
        const response = await generalGetAPI(
            `${API_ENDPOINTS.contractTraceTimeline}?contractNumber=${contract.contractNumber}`
        )
        if (response.isOk) {
            setTimeline(response.data)
        } else {
            toast(baseErrorToastOptions(response.message))
        }
        stopGlobalLoading(loadingID)
    }

    useEffect(() => {
        getContractTraceData()
    }, [contract])

    function parseTimeline(): ParsedTimeline[] {
        const timelineDates: ParsedTimeline[] = []
        const currentStartDate =
            timeline.actualStartDate || (timeline.expectedStartDate as string)
        const currentEndDate =
            timeline.actualEndDate || (timeline.expectedEndDate as string)

        if (timeline.parentContractNumber) {
            const parentStartDate =
                timeline.parentContractActualStartDate ||
                (timeline.parentContractExpectedStartDate as string)
            const parentEndDate =
                timeline.parentContractActualEndDate ||
                (timeline.parentContractExpectedEndDate as string)

            parentStartDate &&
                timelineDates.push({
                    date: [parentStartDate],
                    type: ParsedTimeLineTypes.parent,
                    label: 'contractStartDate',
                    contractNumber: timeline?.parentContractNumber,
                })
            if (
                new Date(currentStartDate).getTime() >
                new Date(parentEndDate).getTime()
            ) {
                timelineDates.push({
                    date: [parentEndDate],
                    type: ParsedTimeLineTypes.parent,
                    label: 'contractEndDate',
                    contractNumber: timeline?.parentContractNumber,
                })
                timelineDates.push({
                    date: [currentStartDate],
                    type: ParsedTimeLineTypes.current,
                    label: 'contractStartDate',
                    contractNumber: timeline?.contractNumber,
                })
            } else if (
                new Date(parentEndDate).getTime() ===
                new Date(currentStartDate).getTime()
            ) {
                timelineDates.push({
                    date: [parentEndDate, currentStartDate],
                    type: ParsedTimeLineTypes.parentCurrent,
                    label: 'contractStartDate',
                    contractNumber: timeline?.contractNumber,
                })
            } else {
                timelineDates.push({
                    date: [currentStartDate],
                    type: ParsedTimeLineTypes.current,
                    label: 'contractStartDate',
                    contractNumber: timeline?.contractNumber,
                })
                timelineDates.push({
                    date: [parentEndDate],
                    type: ParsedTimeLineTypes.parent,
                    label: 'contractEndDate',
                    contractNumber: timeline?.parentContractNumber,
                })
            }
        } else {
            timelineDates.push({
                date: [currentStartDate],
                type: ParsedTimeLineTypes.current,
                label: 'contractStartDate',
                contractNumber: timeline?.contractNumber,
            })
        }

        if (timeline.childContractNumber) {
            const childStartDate =
                timeline.childContractActualStartDate ||
                (timeline.childContractExpectedStartDate as string)
            const childEndDate =
                timeline.childContractActualEndDate ||
                (timeline.childContractExpectedEndDate as string)

            if (
                new Date(childStartDate).getTime() >
                new Date(currentEndDate).getTime()
            ) {
                timelineDates.push({
                    date: [currentEndDate],
                    type: ParsedTimeLineTypes.current,
                    label: 'contractEndDate',
                    contractNumber: timeline?.contractNumber,
                })
                timelineDates.push({
                    date: [childStartDate],
                    type: ParsedTimeLineTypes.child,
                    label: 'contractStartDate',
                    contractNumber: timeline?.childContractNumber,
                })
            } else if (
                new Date(childStartDate).getTime() ===
                new Date(currentEndDate).getTime()
            ) {
                timelineDates.push({
                    date: [currentEndDate, childStartDate],
                    type: ParsedTimeLineTypes.currentChild,
                    label: 'contractStartDate',
                    contractNumber: timeline?.childContractNumber,
                })
            } else {
                timelineDates.push({
                    date: [childStartDate],
                    type: ParsedTimeLineTypes.child,
                    label: 'contractStartDate',
                    contractNumber: timeline?.childContractNumber,
                })
                timelineDates.push({
                    date: [currentEndDate],
                    type: ParsedTimeLineTypes.current,
                    label: 'contractEndDate',
                    contractNumber: timeline?.contractNumber,
                })
            }

            timelineDates.push({
                date: [childEndDate],
                type: ParsedTimeLineTypes.child,
                label: 'contractEndDate',
                contractNumber: timeline?.childContractNumber,
            })
        } else {
            timelineDates.push({
                date: [currentEndDate],
                type: ParsedTimeLineTypes.current,
                label: 'contractEndDate',
                contractNumber: timeline?.contractNumber,
            })
        }
        return timelineDates
    }

    function buildContractTimeline(): ReactElement {
        const parsedTimeline = parseTimeline()
        function colorPicker(type: ParsedTimeLineTypes): string {
            switch (type) {
                case ParsedTimeLineTypes.parent:
                    return inactiveColor
                case ParsedTimeLineTypes.current:
                    return activeColor
                case ParsedTimeLineTypes.child:
                    return inactiveColor
                case ParsedTimeLineTypes.parentCurrent:
                    return inactiveColor
                default:
                    return activeColor
            }
        }

        return (
            <Flex width={'100%'}>
                {parsedTimeline.map((item, index) => (
                    <Flex
                        className="WRAPPER"
                        key={index}
                        justifyContent={'center'}
                        flex={index === parsedTimeline.length - 1 ? 0 : 1}
                        flexDirection={'column'}
                    >
                        <Box>
                            <Flex flexDirection={'column'}>
                                <div>
                                    {item.date[0]
                                        ? formatDate(item.date[0])
                                        : 'No date'}
                                </div>
                                <div>
                                    {item?.contractNumber
                                        ? item.contractNumber
                                        : ''}
                                </div>
                                <div>
                                    {translate(item?.label ? item.label : '')}
                                </div>
                            </Flex>
                        </Box>
                        <Flex alignItems={'center'} w={'100%'}>
                            <Flex
                                w={'30px'}
                                h="30px"
                                bg={colorPicker(item.type)}
                                borderRadius={'100%'}
                                alignItems={'center'}
                                justifyContent={'center'}
                            >
                                {item.date[1] ? (
                                    <Box
                                        w={'15px'}
                                        h="15px"
                                        bg={
                                            item.type ===
                                            ParsedTimeLineTypes.parentCurrent
                                                ? activeColor
                                                : inactiveColor
                                        }
                                    >
                                        {item.date[1]}
                                    </Box>
                                ) : (
                                    <></>
                                )}
                            </Flex>
                            {index + 1 < parsedTimeline.length && (
                                <Box
                                    height={'2px'}
                                    flex={1}
                                    border={'2px solid'}
                                    borderColor={inactiveColor}
                                ></Box>
                            )}
                        </Flex>
                    </Flex>
                ))}
            </Flex>
        )
    }

    return (
        <GenericBox p={8}>
            <Heading size={'xl'} pb={'1.5rem'}>
                {translate('timeline')}
            </Heading>
            <Flex mb={8}>
                {timeline.parentContractNumber ? (
                    <>
                        <Heading
                            size={'md'}
                            mb={'8px'}
                            color={inactiveColor}
                            mr={2}
                        >
                            <chakra.span
                                as={Link}
                                onClick={(): void => {
                                    navigate(
                                        `/contracts/${timeline.parentContractNumber}`
                                    )
                                }}
                            >
                                {timeline.parentContractNumber}
                            </chakra.span>
                            {' >'}
                        </Heading>
                    </>
                ) : (
                    <></>
                )}

                <Heading size={'md'} mb={'8px'} color={activeColor}>
                    {timeline.contractNumber}
                </Heading>

                {timeline.childContractNumber ? (
                    <Heading
                        ml={2}
                        size={'md'}
                        mb={'8px'}
                        color={inactiveColor}
                    >
                        <chakra.span
                            as={Link}
                            onClick={(): void => {
                                navigate(
                                    `/contracts/${timeline.childContractNumber}`
                                )
                            }}
                        >
                            {timeline.childContractNumber}
                        </chakra.span>
                        {' >'}
                    </Heading>
                ) : (
                    <></>
                )}
            </Flex>
            {buildContractTimeline()}
            <Flex alignItems={'center'} gap={2} mt={8}>
                <Flex alignItems={'center'} gap={2}>
                    <Box
                        w={'10px'}
                        h="10px"
                        bg={inactiveColor}
                        borderRadius={'100%'}
                    ></Box>
                    <Box>{translate('parentAndChildContract')}</Box>
                </Flex>
                <Flex alignItems={'center'} gap={2}>
                    <Box
                        w={'10px'}
                        h="10px"
                        bg={activeColor}
                        borderRadius={'100%'}
                    ></Box>
                    <Box>{translate('currentContract')}</Box>
                </Flex>
            </Flex>
        </GenericBox>
    )
}
