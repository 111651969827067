import camelCase from 'lodash/camelCase'
import { EditIcon, DeleteIcon } from '@chakra-ui/icons'
import { Tooltip, IconButton } from '@chakra-ui/react'
import { IFormFieldSections } from '../../../features/dynamicForm/DynamicForm.interfaces'
import { regionNames } from '../../../utils/constants.utils'
import {
    buildListOfKeyValues,
    ColumnActionConfiguration,
    getSpecificKeyFromObject,
} from '../../../utils/functions.utils'
import { CountryCode, LocationDTO } from '../../../utils/types/types'

export const locationColumns = (
    translate: Function,
    handleEditLocation: Function,
    handleDeleteLocation: Function
) => [
    {
        field: 'locationNumber',
        headerName: translate('locationNumber'),
        filter: 'agTextColumnFilter',
    },
    {
        field: 'locationName',
        headerName: translate('locationName'),

        filter: 'agTextColumnFilter',
    },
    {
        field: 'countryCode',
        headerName: translate('countryCode'),
        filter: 'agTextColumnFilter',
        valueGetter: (params: any) =>
            translate(
                camelCase(
                    getSpecificKeyFromObject<typeof CountryCode>(
                        CountryCode,
                        params.data.countryCode
                    )
                )
            ),
    },
    {
        field: 'city',
        headerName: translate('city'),
        filter: 'agTextColumnFilter',
    },
    {
        field: 'street',
        headerName: translate('street'),

        filter: 'agTextColumnFilter',
    },
    {
        field: 'building',
        headerName: translate('building'),
        filter: 'agTextColumnFilter',
    },
    {
        field: 'floor',
        headerName: translate('floor'),
        filter: 'agTextColumnFilter',
    },
    {
        field: 'postalCode',
        headerName: translate('postalCode'),
        filter: 'agTextColumnFilter',
    },
    {
        field: 'contactName',
        headerName: translate('contactName'),

        filter: 'agTextColumnFilter',
    },
    {
        field: 'phone',
        headerName: translate('phone'),
        filter: 'agTextColumnFilter',
    },
    {
        field: 'latitude',
        headerName: translate('latitude'),
        filter: 'agTextColumnFilter',
    },
    {
        field: 'longitude',
        headerName: translate('longitude'),
        filter: 'agTextColumnFilter',
    },
    {
        field: 'customerNumber',
        headerName: translate('customerNumber'),
        filter: 'agTextColumnFilter',
        flex: 1,
    },
    {
        ...ColumnActionConfiguration(
            translate,
            (params: any) => (
                <>
                    <Tooltip label={translate('edit')} placement="top" hasArrow>
                        <IconButton
                            mr={2}
                            aria-label="Edit"
                            size="sm"
                            onClick={(): void =>
                                handleEditLocation(params.data)
                            }
                            icon={<EditIcon />}
                        />
                    </Tooltip>

                    <Tooltip
                        label={translate('delete')}
                        placement="top"
                        hasArrow
                    >
                        <IconButton
                            aria-label="delete"
                            size="sm"
                            onClick={(): Promise<void> =>
                                handleDeleteLocation(params.data)
                            }
                            icon={<DeleteIcon />}
                        />
                    </Tooltip>
                </>
            ),
            100
        ),
    },
]

export const FORM_FIELDS_LOCATION: IFormFieldSections<Partial<LocationDTO>> = {
    part1: {
        title: '',
        columns: [1],
        fields: [
            {
                slug: 'locationName',
                label: 'locationName',
                inputProps: { type: 'text', isRequired: true },
            },
        ],
    },
    part2: {
        title: '',
        columns: [2],
        fields: [
            {
                slug: 'street',
                label: 'street',
                inputProps: { type: 'text', isRequired: true },
            },
            {
                slug: 'building',
                label: 'building',
                inputProps: { type: 'text', isRequired: true },
            },
            {
                slug: 'floor',
                label: 'floor',
                inputProps: { type: 'text', isRequired: true },
            },
            {
                slug: 'postalCode',
                label: 'postalCode',
                inputProps: { type: 'text', isRequired: true },
            },
            {
                slug: 'countryCode',
                label: 'countryCode',
                inputProps: {
                    type: 'select',
                    isRequired: true,
                },
                optionsKeysValues: buildListOfKeyValues<typeof CountryCode>(
                    CountryCode
                ).map((elem) => {
                    const option: any = elem
                    option[1] = regionNames.of(elem[1])
                    return option
                }),
                skipTranslation: true,
            },
            {
                slug: 'city',
                label: 'city',
                inputProps: { type: 'text', isRequired: true },
            },
        ],
    },
    contact: {
        title: '',
        columns: [2],
        fields: [
            {
                slug: 'contactName',
                label: 'contactName',
                inputProps: { type: 'text', isRequired: true },
            },
            {
                slug: 'phone',
                label: 'phone',
                inputProps: { type: 'text', isRequired: true },
            },
        ],
    },
}
