import { FileUpload } from 'use-file-upload'

import API_ENDPOINTS from '../../../../../../services/API/apiEndpoints.constants'
import {
    generalFilePostAPI,
    generalFilePutAPI,
    generalGetAPI,
    generalPostAPI,
} from '../../../../../../services/API/general.api'
import { DocumentTemplateContentDTO } from '../../../../../../utils/types/types'

export const fetchAllDocumentTemplates = async (
    handleSuccessfullResponse: Function,
    setIsLoading: Function,
    productId?: number
): Promise<void> => {
    setIsLoading(true)
    try {
        const response = await generalGetAPI(
            productId
                ? `${API_ENDPOINTS.documentTemplate}?productId=${productId}`
                : `${API_ENDPOINTS.documentTemplate}`
        )

        if (response.isOk) {
            handleSuccessfullResponse(response.data)
        } else {
            console.error(
                'Error fetching document templates:',
                response.message
            )
        }
    } catch (error) {
        console.error(
            'An error occurred while fetching document template:',
            error
        )
    } finally {
        setIsLoading(false)
    }
}

export const createDocumentTemplate = async (
    selectedDocumentType: number,
    updatedTemplateContent: DocumentTemplateContentDTO | null,
    productId: number,
    handleSuccessfullResponse: Function,
    handleUnsuccessfullResponse: Function,
    setIsLoading: Function,
    documentName: string,
    headerHeight: string,
    footerHeight: string,
    appendixDocument?: FileUpload
): Promise<void> => {
    setIsLoading(true)
    try {
        if (
            !updatedTemplateContent?.bodyContent ||
            !updatedTemplateContent?.headerContent ||
            !updatedTemplateContent?.footerContent
        ) {
            throw new Error('Please fill all the fields')
        }
        const formData = new FormData()
        formData.set('name', documentName)
        formData.set('headerHeight', `${headerHeight}cm`)
        formData.set('footerHeight', `${footerHeight}cm`)
        formData.set('bodyContent', updatedTemplateContent.bodyContent)
        formData.set('headerContent', updatedTemplateContent?.headerContent)
        formData.set('footerContent', updatedTemplateContent?.footerContent)
        appendixDocument &&
            formData.set('appendixDocument', appendixDocument.file)
        formData.set('documentType', `${selectedDocumentType}`)
        formData.set('templateType', '1')

        const response = await generalFilePostAPI(
            `${API_ENDPOINTS.documentTemplate}`,
            formData
        )
        if (response.isOk && response?.data?.id) {
            const response2 = await generalPostAPI(
                `${API_ENDPOINTS.documentTemplateProductMap}`,
                {
                    productId,
                    templateId: response.data.id,
                }
            )
            if (response2.isOk) {
                handleSuccessfullResponse(response)
            } else {
                handleUnsuccessfullResponse(response2)
            }
        } else {
            handleUnsuccessfullResponse(response)
        }
    } catch (error) {
        console.error(
            'An error occurred while creating document template:',
            error
        )
    } finally {
        setIsLoading(false)
    }
}

export const updateDocumentTemplate = async (
    templateId: number,
    updatedTemplateContent: DocumentTemplateContentDTO | null,
    handleSuccessfullResponse: Function,
    handleUnsuccessfullResponse: Function,
    setIsLoading: Function,
    appendixDocument: FileUpload,
    documentName: string,
    headerHeight: string,
    footerHeight: string
): Promise<void> => {
    setIsLoading(true)
    try {
        const formData = new FormData()
        formData.append('name', documentName)
        formData.append('headerHeight', `${headerHeight}cm`)
        formData.append('footerHeight', `${footerHeight}cm`)
        formData.append(
            'bodyContent',
            updatedTemplateContent?.bodyContent ?? ''
        )
        formData.append(
            'headerContent',
            updatedTemplateContent?.headerContent ?? ''
        )
        formData.append(
            'footerContent',
            updatedTemplateContent?.footerContent ?? ''
        )
        appendixDocument &&
            formData.set('appendixDocument', appendixDocument.file)
        formData.append('id', `${templateId}`)

        const response = await generalFilePutAPI(
            `${API_ENDPOINTS.documentTemplate}`,
            formData
        )
        if (response.isOk) {
            handleSuccessfullResponse(response)
        } else {
            handleUnsuccessfullResponse(response)
        }
    } catch (error) {
        console.error(
            'An error occurred while fetching document template:',
            error
        )
    } finally {
        setIsLoading(false)
    }
}
