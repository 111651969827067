import { IFormField } from '../../../../../features/dynamicForm/DynamicForm.feature'
import { TransientFeeDTO } from '../../../../../utils/types/types'
import { isEmpty } from '../../../../../utils/functions.utils'

export default function TransientFeeFormFields(
    editFee: TransientFeeDTO,
    activePartner: any,
    VATOptions: any[][],
    hasDiscount: boolean
): IFormField<Partial<TransientFeeDTO>>[] {
    return [
        {
            errorMessage: 'Invalid Description',
            inputProps: { type: 'text', isRequired: true },
            label: 'description',
            slug: 'text',
            validation: (value: string) => value.length > 0,
        },
        {
            errorMessage: 'Invalid Quantity',
            inputProps: { type: 'number', isRequired: true, min: 0 },
            label: 'quantity',
            slug: 'quantity',
            value: editFee.quantity,
        },
        {
            errorMessage: 'Invalid Amount',
            inputProps: { type: 'number', isRequired: true },
            label: 'amount',
            rightAddonText: activePartner?.currencyName ?? 'DKK',
            slug: 'value',
            validation: (value: string) => !Number.isNaN(parseFloat(value)),
            value: editFee.value,
        },
        {
            errorMessage: 'Please select a Value',
            inputProps: { type: 'select', isRequired: true },
            label: 'vat',
            optionsKeysValues: VATOptions,
            slug: 'vatId',
            validation: (value: string) => !isEmpty(value),
        },
        ...(hasDiscount
            ? [
                  {
                      inputProps: { type: 'number' },
                      label: 'discount',
                      rightAddonText: '%',
                      slug: 'discount',
                      value: editFee.discount?.value,
                  },
              ]
            : []),
    ]
}
