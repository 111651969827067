import { ReactElement, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'

import {
    Box,
    chakra,
    Flex,
    Spinner,
    Tab,
    TabList,
    Tabs,
} from '@chakra-ui/react'

import useIsMounted from '../../services/hooks/isMounted'
import GenericBox from '../genericBox/genericBox'

export interface TabListType {
    id: number
    text: string
    link?: string
    secondary?: boolean
}
interface IPageContainer {
    id?: string
    children: any
    tabsList?: TabListType[]
    pageHeader?: ReactElement
    pageFooter?: ReactElement
    noPadding?: boolean
    isLoading?: boolean
}

export default function PageContainer({
    id,
    children,
    tabsList,
    pageHeader,
    pageFooter,
    noPadding = false,
    isLoading = false,
}: IPageContainer): ReactElement {
    const translate = useTranslation().t
    const navigate = useNavigate()
    const location = useLocation()
    const isMounted = useIsMounted()
    const [currentTab, setCurrentTab] = useState(0)
    const [padding, setPadding] = useState('20px')
    const [paddingNoTabs, setPaddingNoTabs] = useState<any>(0)
    const topDistance = pageHeader ? '75px' : '0'
    const bottonDistance = pageFooter ? '80px' : '0'

    const hideSave = !!(
        location.pathname.includes('template') && currentTab === 6
    )

    useEffect((): void => {
        if (tabsList?.[0].link) {
            const selectedTab = tabsList?.find((tab) =>
                location.pathname.match(`${tab.link}/*`)
            )
            if (isMounted() && selectedTab) {
                setCurrentTab(selectedTab?.id)
            }
            if (selectedTab && selectedTab?.link) navigate(selectedTab.link)
            else navigate(tabsList?.[0]?.link as string)
        }
    }, [isMounted])

    useEffect(() => {
        const hasTabs = tabsList?.[currentTab]?.secondary
        setPadding(hasTabs ? '0' : '6')
    }, [currentTab])

    useEffect(() => {
        if (noPadding) {
            setPaddingNoTabs(0)
        } else {
            setPaddingNoTabs([2, 2, 6])
        }
    }, [noPadding])

    function loadingComponent(): ReactElement {
        return (
            <chakra.div
                backgroundColor="#000000AA"
                className="ln-global-loader"
            >
                <Spinner
                    thickness="4px"
                    speed="0.65s"
                    emptyColor="gray.200"
                    color={'primary.500'}
                    size="xl"
                    position="absolute"
                    top="calc(50% - 33px)"
                    left="calc(50% - 33px)"
                />
            </chakra.div>
        )
    }

    return (
        <Flex
            flexDirection={'column'}
            overflow={'hidden'}
            className="page-container-wrapper"
            w="100%"
            h="100%"
            id={id}
        >
            {pageHeader && (
                <GenericBox
                    display={'flex'}
                    width="100%"
                    h="5rem"
                    alignItems="center"
                    px={'10px'}
                    justifyContent="space-between"
                    borderRadius={0}
                >
                    {pageHeader}
                </GenericBox>
            )}
            {tabsList && tabsList?.length > 0 ? (
                <Tabs
                    isLazy
                    w={'100%'}
                    h={'100%'}
                    index={currentTab}
                    top={topDistance}
                    left={0}
                    bottom={bottonDistance}
                    overflow={'hidden'}
                >
                    <TabList
                        maxWidth={'100%'}
                        overflow={'auto'}
                        position={'absolute'}
                        left={0}
                        right={0}
                        zIndex={100}
                        css={{
                            '&::-webkit-scrollbar': {
                                height: 0,
                                width: 0,
                                display: 'hidden',
                            },
                            '&::-webkit-scrollbar-track': {
                                display: 'hidden',
                            },
                            '&::-webkit-scrollbar-thumb': {
                                display: 'hidden',
                            },
                        }}
                    >
                        {tabsList.map((tab, index) => (
                            <Tab
                                id={tab.id.toString()}
                                key={tab.text}
                                onClick={(): void => {
                                    if (tab.link) {
                                        navigate(tab.link)
                                        setCurrentTab(tab.id)
                                    } else {
                                        setCurrentTab(tab.id)
                                    }
                                }}
                            >
                                {translate(tab.text)}
                            </Tab>
                        ))}
                    </TabList>
                    <Box
                        className="page-container-content"
                        padding={[2, 2, padding]}
                        overflow={'scroll'}
                        height={'100%'}
                        w={'100%'}
                        mt={'42px'}
                    >
                        {!isLoading ? children : loadingComponent()}
                    </Box>
                </Tabs>
            ) : (
                <Box
                    top={topDistance}
                    w={'100%'}
                    height={'100%'}
                    bottom={bottonDistance}
                    overflow={'auto'}
                    className="page-container-content-notabs"
                    padding={paddingNoTabs}
                >
                    {!isLoading ? children : loadingComponent()}
                </Box>
            )}
            {pageFooter && (
                <GenericBox
                    overflow={'none'}
                    p={4}
                    w={'100%'}
                    h="5rem"
                    zIndex={2}
                    borderRadius={0}
                >
                    {!hideSave && pageFooter}
                </GenericBox>
            )}
        </Flex>
    )
}
