import 'react-toastify/dist/ReactToastify.css'

import { ReactElement } from 'react'
import { ToastContainer } from 'react-toastify'

import { Box, Flex, useColorModeValue } from '@chakra-ui/react'

import {
    LayoutContextProvider,
    useLayout,
} from '../../services/contexts/LayoutServices.context'
import { useScreenSize } from '../../utils/media-query'
import HeaderComponent from './components/header/header'
import Sidebar from './components/sidebar/sidebar'

function Layout({ children }: any): ReactElement {
    const { isMenuOpen } = useLayout()
    const screenSize = useScreenSize()
    const color = useColorModeValue('white', 'secondary.900')

    function renderMobile() {
        return (
            <Flex width={'100%'} height={'100vh'} direction={'column'}>
                <Box position={'relative'} zIndex={99}>
                    <Sidebar />
                    {!isMenuOpen && <HeaderComponent />}
                </Box>
                <Box
                    width={'100%'}
                    overflowX={'hidden'}
                    maxH={'100vh'}
                    height={'100vh'}
                    top={'70px'}
                >
                    <Box className={'container'} height={'100%'}>
                        {children}
                        <ToastContainer />
                    </Box>
                </Box>
            </Flex>
        )
    }

    function renderDesktop(): ReactElement {
        return (
            <Flex height={'100vh'} bg={color}>
                <Box position={'relative'}>
                    <Sidebar />
                </Box>
                <Box
                    width={'100%'}
                    overflowX={'hidden'}
                    maxH={'100vh'}
                    h={'100%'}
                    position={'relative'}
                    overflowY={'hidden'}
                >
                    {children}
                    <ToastContainer />
                </Box>
            </Flex>
        )
    }

    return screenSize.isXSmall ? renderMobile() : renderDesktop()
}

const LayoutWithContext = ({ children }: any) => (
    <LayoutContextProvider>
        <Layout> {children}</Layout>
    </LayoutContextProvider>
)

export default LayoutWithContext
