import { DeleteIcon, EditIcon } from '@chakra-ui/icons'
import { IconButton, Tooltip } from '@chakra-ui/react'

import { ColumnActionConfiguration } from '../../../../../utils/functions.utils'
import { TransientFeeDTO } from '../../../../../utils/types/types'

export const columnsConfig = (
    translate: Function,
    formatValue: Function,
    hasDiscount: boolean,
    onEditTransientFee?: (transientFee: TransientFeeDTO) => void,
    onDeleteTransientFee?: (transientFee: TransientFeeDTO) => void
) => [
    { headerName: translate('description'), field: 'text' },
    {
        headerName: translate('quantity'),
        field: 'quantity',
        valueFormatter: (params: any) => formatValue(params, 'quantity'),
    },
    {
        headerName: translate('amount'),
        field: 'value',
        valueFormatter: (params: any) => formatValue(params, 'value'),
    },
    {
        headerName: translate('vatPercentage'),
        field: 'vatPercentage',
        filter: 'agTextColumnFilter',
        valueGetter: (params: any) => `${params.data.vatPercentage}%`,
    },
    { headerName: translate('isConsumed'), field: 'isConsumed' },
    ...(hasDiscount
        ? [
              {
                  headerName: translate('discount'),
                  field: 'discount',
                  filter: 'agTextColumnFilter',
                  valueGetter: (params: any) =>
                      params.data?.discount?.value
                          ? `${params.data.discount.value}%`
                          : '',
              },
          ]
        : []),
    {
        ...ColumnActionConfiguration(translate, (params: any) => (
            <>
                <Tooltip
                    label={translate('editTransientFee')}
                    placement="top"
                    hasArrow
                >
                    <IconButton
                        aria-label="Edit"
                        size="sm"
                        onClick={(): void =>
                            onEditTransientFee &&
                            onEditTransientFee(params.data)
                        }
                        mr={2}
                        icon={<EditIcon />}
                    />
                </Tooltip>
                <Tooltip
                    label={translate('deleteTransientFee')}
                    placement="top"
                    hasArrow
                >
                    <IconButton
                        aria-label="Delete"
                        size="sm"
                        onClick={(): void =>
                            onDeleteTransientFee &&
                            onDeleteTransientFee(params.data)
                        }
                        icon={<DeleteIcon />}
                    />
                </Tooltip>
            </>
        )),
    },
]
