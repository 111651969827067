import { ReactElement, useEffect } from 'react'
import { Box, Divider, Heading, HStack, Text, VStack } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { useContractService } from '../../../../services/contract/Contract.services'
import { useCustomer } from '../../../../services/customer/Customer.services'
import GenericBox from '../../../../components/genericBox/genericBox'
import { CountryCode } from '../../../../utils/types/types'

export default function CustomerInfoPreview(): ReactElement {
    const { contract } = useContractService()
    const { customer, retrieveCustomer } = useCustomer()
    const translate = useTranslation().t

    const { customerNumber } = contract

    const loadCustomer = async (): Promise<void> => {
        await retrieveCustomer(customerNumber)
    }

    useEffect((): void => {
        loadCustomer()
    }, [customerNumber])

    return (
        <GenericBox rounded="lg" p={6}>
            <Box>
                {/* Basic Information */}
                <VStack align="start" spacing={4}>
                    <Heading size="md" opacity={'0.8'}>
                        Basic Information
                    </Heading>
                    <HStack justify="space-between" w="full">
                        <Text fontWeight="600">
                            {translate('customerNumber')}:
                        </Text>
                        <Text>{customer.customerNumber}</Text>
                    </HStack>
                    <HStack justify="space-between" w="full">
                        <Text fontWeight="600">{translate('name')}:</Text>
                        <Text>{customer.name}</Text>
                    </HStack>
                    <HStack justify="space-between" w="full">
                        <Text fontWeight="600">
                            {translate('customerCvr')}:
                        </Text>
                        <Text>{customer.cvr}</Text>
                    </HStack>
                </VStack>

                <Divider my={4} />

                {/* Contact Details */}
                <VStack align="start" spacing={4}>
                    <Heading size="md" opacity={'0.8'}>
                        Contact Details
                    </Heading>
                    <HStack justify="space-between" w="full">
                        <Text fontWeight="600">{translate('email')}:</Text>
                        <Text>{customer.email}</Text>
                    </HStack>
                    <HStack justify="space-between" w="full">
                        <Text fontWeight="600">{translate('phone')}:</Text>
                        <Text>{customer.phone}</Text>
                    </HStack>
                    <HStack justify="space-between" w="full">
                        <Text fontWeight="600">
                            {translate('invoicingEmail')}:
                        </Text>
                        <Text>{customer.invoicingEmail}</Text>
                    </HStack>
                    <HStack justify="space-between" w="full">
                        <Text fontWeight="600">
                            {translate('contactName')}:
                        </Text>
                        <Text>{customer.contactName}</Text>
                    </HStack>
                </VStack>

                <Divider my={4} />

                {/* Address */}
                <VStack align="start" spacing={4}>
                    <Heading size="md" opacity={'0.8'}>
                        Address
                    </Heading>
                    <HStack justify="space-between" w="full">
                        <Text fontWeight="600">{translate('address')}:</Text>
                        <Text>{customer.street}</Text>
                    </HStack>
                    <HStack justify="space-between" w="full">
                        <Text fontWeight="600">{translate('building')}:</Text>
                        <Text>{customer.building}</Text>
                    </HStack>
                    {!!customer?.floor && (
                        <HStack justify="space-between" w="full">
                            <Text fontWeight="600">{translate('floor')}:</Text>
                            <Text>{customer.floor}</Text>
                        </HStack>
                    )}
                    <HStack justify="space-between" w="full">
                        <Text fontWeight="600">{translate('postalCode')}:</Text>
                        <Text>{customer.postalCode}</Text>
                    </HStack>
                    <HStack justify="space-between" w="full">
                        <Text fontWeight="600">{translate('city')}:</Text>
                        <Text>{customer.city}</Text>
                    </HStack>
                    {!!customer?.country && (
                        <HStack justify="space-between" w="full">
                            <Text fontWeight="600">
                                {translate('country')}:
                            </Text>
                            <Text>{CountryCode[customer.country]}</Text>
                        </HStack>
                    )}
                </VStack>

                <Divider my={4} />

                {/* Address */}
                <VStack align="start" spacing={4}>
                    <Heading size="md" opacity={'0.8'}>
                        External References
                    </Heading>
                    <HStack justify="space-between" w="full">
                        <Text fontWeight="600">
                            {translate('externalReferenceString1')}:
                        </Text>
                        <Text>{customer.externalReferenceString1}</Text>
                    </HStack>
                    {!!customer?.externalReferenceString2 && (
                        <HStack justify="space-between" w="full">
                            <Text fontWeight="600">
                                {translate('externalReferenceString2')}:
                            </Text>
                            <Text>{customer.externalReferenceString2}</Text>
                        </HStack>
                    )}
                    {!!customer?.externalReferenceString3 && (
                        <HStack justify="space-between" w="full">
                            <Text fontWeight="600">
                                {translate('externalReferenceString3')}:
                            </Text>
                            <Text>{customer.externalReferenceString3}</Text>
                        </HStack>
                    )}
                    {!!customer?.externalReferenceString4 && (
                        <HStack justify="space-between" w="full">
                            <Text fontWeight="600">
                                {translate('externalReferenceString4')}:
                            </Text>
                            <Text>{customer.externalReferenceString4}</Text>
                        </HStack>
                    )}
                    {!!customer?.externalReferenceText1 && (
                        <HStack justify="space-between" w="full">
                            <Text fontWeight="600">
                                {translate('externalReferenceText1')}:
                            </Text>
                            <Text>{customer.externalReferenceText1}</Text>
                        </HStack>
                    )}
                </VStack>
            </Box>
        </GenericBox>
    )
}
