/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { ReactElement, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { CloseIcon } from '@chakra-ui/icons'
import {
    Button,
    Flex,
    Heading,
    Input,
    InputGroup,
    InputRightElement,
    Tab,
    TabList,
    TabPanel,
    TabPanels,
    Tabs,
} from '@chakra-ui/react'

import withModalHOC from '../../hoc/modal.hoc'
import CustomerTableComponent from '../../pages/contractDetail/components/customer/CustomerTable.component'
import { CUSTOMER_FORM } from '../../pages/customerDetails/CustomerDetails.config'
import { useUser } from '../../services/contexts/user.context'
import {
    CustomerChangeContextProvider,
    useCustomerChange,
} from '../../services/customer/CustomerChange.service'
import { deepCopy } from '../../utils/functions.utils'
import {
    CountryCode,
    CustomerDTO,
    InvoiceCommunicationType,
} from '../../utils/types/types'
import DynamicForm from '../dynamicForm/DynamicForm.feature'

export const DEFAULT_CUSTOMER_DTO: CustomerDTO = {
    canBeDeleted: false,
    country: CountryCode.DK,
    customerNumber: '',
    customerType: 0,
    invoiceCommunication: InvoiceCommunicationType.None,
}

function AddCustomerContainer(props: any): ReactElement {
    const [customer, setCustomer] = useState(
        props?.customer ? props.customer : deepCopy(DEFAULT_CUSTOMER_DTO)
    )
    const { user } = useUser()
    const {
        creditSafeCustomers,
        customer: selectedCustomer,
        customerSearch,
        customerSearchPending,
        handleCustomerSearch,
        handleResetCustomerSearch,
        setCustomerSearch,
    } = useCustomerChange()
    const translate = useTranslation().t
    const [tabIndex, setTabIndex] = useState(0)

    useEffect(() => {
        if (selectedCustomer) {
            setTabIndex(0)
            setCustomer({ ...selectedCustomer })
        }
    }, [selectedCustomer])

    return (
        <Tabs
            index={tabIndex}
            mt={2}
            onChange={(index): void => setTabIndex(index)}
        >
            <TabList>
                <Tab> {translate('customerDetails')}</Tab>
                <Tab> {translate('customerSelect')}</Tab>
            </TabList>

            <TabPanels>
                <TabPanel p={6} width={'100%'} textAlign={'center'}>
                    <DynamicForm<Partial<CustomerDTO>>
                        columns={{ base: 1, md: 2, lg: 4 }}
                        submitText="saveChanges"
                        onSubmit={(data: CustomerDTO): void => {
                            props.handleSaveProduct(data)
                            props.onClose()
                        }}
                        data={customer}
                        submitInline
                        formFieldsWithSections={CUSTOMER_FORM(
                            user.culture,
                            props?.readOnly
                        )}
                        hideSubmit={props?.readOnly}
                    />
                </TabPanel>
                <TabPanel p={6} width={'100%'} textAlign={'left'}>
                    <Heading size={'md'} mb={4}>
                        {translate('customer')}
                    </Heading>
                    <form
                        onSubmit={(event): void => {
                            event.preventDefault()
                            handleCustomerSearch()
                        }}
                    >
                        <Flex gap={2} alignItems={'end'} w={'100%'}>
                            <InputGroup>
                                <Input
                                    isDisabled={customerSearchPending}
                                    onChange={(event): void =>
                                        setCustomerSearch(event.target.value)
                                    }
                                    placeholder={
                                        translate('searchCustomer') || ''
                                    }
                                    value={customerSearch}
                                    w={'100%'}
                                />
                                {customerSearch.length > 0 && (
                                    <InputRightElement
                                        onClick={handleResetCustomerSearch}
                                    >
                                        <CloseIcon />
                                    </InputRightElement>
                                )}
                            </InputGroup>
                            <Button onClick={handleCustomerSearch} px={'8'}>
                                {translate('searchCustomer')}
                            </Button>
                        </Flex>
                    </form>
                    {creditSafeCustomers !== undefined && (
                        <CustomerTableComponent
                            customers={creditSafeCustomers}
                            enableSearch={false}
                        />
                    )}
                </TabPanel>
            </TabPanels>
        </Tabs>
    )
}

const AddCustomerContainerWithContext = (props: any) => (
    <CustomerChangeContextProvider>
        <AddCustomerContainer {...props} />
    </CustomerChangeContextProvider>
)

export default withModalHOC(AddCustomerContainerWithContext)
